.modal-container-title {
  font-size: 4.5vh;
  font-weight: bold;
  letter-spacing: .6vw;
  text-transform: uppercase;
}

.modal-container-text {
  font-size: 2.3vh;
  color: #a2b7ce;
  text-align: justify;
  padding: 0 5vw;
}

.modal-container-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modal-container-links-link {
  margin: 0 1.5vw;
  cursor: pointer;
  fill: white;
  transition: fill ease .4s;
  width: 5vh;
  height: 5vh;
}

.modal-container-links-link:hover {
  fill: #a2b7ce;
  transition: fill ease .4s;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50% 50%;
  }
}

@media (orientation: portrait) and (max-width: 912px) {
  .modal-container-title {
    font-size: 2em;
  }
  .modal-container-text {
    font-size: 1.2em;
  }
  .modal-container-links-link {
    width: 40px;
    height: 40px;
    margin: 0 2vw;
  }
}

@media (orientation: portrait) and (max-width: 700px) {
  .modal-container-title {
    font-size: 1.5em;
  }
  .modal-container-text {
    font-size: .9em;
  }
  .modal-container-links-link {
    width: 30px;
    height: 30px;
    margin: 0 2vw;
  }
}

@media (orientation: portrait) and (max-width: 500px) {
  .modal-container-title {
    font-size: 1em;
  }
  .modal-container-text {
    font-size: .8em;
  }
  .modal-container-links-link {
    width: 20px;
    height: 20px;
    margin: 0 2vw;
  }
}

@media (orientation: portrait) and (max-width: 370px) {
  .modal-container-title {
    font-size: .9em;
  }
  .modal-container-text {
    font-size: .8em;
  }
}

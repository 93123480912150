.primary-button {
  border-radius: 5vh;
  height: 50px;
  min-height: 35px;
  width: 250px;
  max-width: 30vw;
  background-color: #f2e370;
  border: 1px solid #f2e370;
  color: #1d4065;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color ease-out .4s, color ease-out .4s;
}

.primary-button:hover {
  background-color: #1d4065;
  color: #f2e370;
  transition: background-color ease-out .4s, color ease-out .4s;
}

.main-container {
  height: calc(100vh - 132px);
  width: 1200px;
  max-width: 90%;
  margin: auto;
  display: inline-grid;
  grid-template:
    "visual visual" 3fr
    "btn btn" 1fr
    "text text" 1fr / 1fr;
  align-items: center;
}

.main-container-image {
  grid-area: visual;
  border-radius: 100%;
  width: 30vw;
  height: 30vw;
  max-width: 300px;
  max-height: 300px;
  margin: 0 auto;
  cursor: default;
  background-color: #1d4065;
  position: relative;

  box-shadow: 0 0 .5vh white;
  animation: glow 8s infinite;
  -webkit-animation: glow 8s infinite;
  z-index: 3;
  grid-area: visual;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.main-container-image-circle {
  animation: spin 40s linear infinite;
  fill: white;
  justify-self: center;
  position: absolute;

  width: 95%;
  height: 95%;

  top: 2.5%;
}

.main-container-image-circle-inner {
  animation: spinBack 40s linear infinite;
  fill: white;
  justify-self: center;
  position: absolute;

  width: 60%;
  height: 60%;

  top: 20%;
}

.main-container-buttons {
  grid-area: btn;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  max-width: 500px;
  margin: auto;
}

.main-container-stats {
  grid-area: text;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #a2b7ce;
}

.main-container-stats-unit {
  cursor: default;
}

.main-container-stats-unit-title {
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.main-container-stats-unit-value {
  color: #f2f1fe;
  font-size: 2rem;
  font-weight: 700;
}

@keyframes spin {
  from { transform:rotate(360deg); }
  to { transform:rotate(0deg); }
}

@keyframes spinBack {
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
}

@keyframes bounce {
  0% { transform: translateY(0); }
  25% { transform: translateY(1.5vh); }
  50% { transform: translateY(0); }
  75% { transform: translateY(-1.5vh); }
  100% { transform: translateY(0); }
}

@keyframes bounceSmall {
  0% { transform: translateY(0); }
  25% { transform: translateY(.8vh); }
  50% { transform: translateY(0); }
  75% { transform: translateY(-.8vh); }
  100% { transform: translateY(0); }
}

@keyframes glow {
  0% { box-shadow: 0 0 .5vh white }
  50% { box-shadow: 0 0 3.5vh white }
  100% { box-shadow: 0 0 .5vh white }
}

@media screen and (max-width: 1230px) {
  .main-container {
    grid-template:
      "visual btn" 1fr
      "visual btn" 1fr
      "text text" 1fr / 1fr;
  }
  .main-container-image {
    margin: 0;
  }
}

@media screen and (max-width: 959px) {
  .main-container {
    grid-template:
      "visual visual" 3fr
      "btn btn" 1fr
      "text text" 1fr / 1fr;
  }
  .main-container-image {
    margin: 0 auto;
  }
  .main-container-buttons > button {
    width: 23vw !important;
    min-width: 100px;
  }
  .main-container-image {
    min-width: 300px;
    min-height: 300px;
  }
}

@media screen and (max-width: 740px) {
  .main-container-buttons {
    width: 55vw;
  }
  .main-container-buttons > button {
    height: 40px !important;
    min-width: 130px;
  }
}

@media screen and (max-width: 570px) {
  .main-container {
    margin: 0 auto;
    height: calc(100vh - 100px);
    grid-template:
      "visual visual" 1fr
      "btn btn" 1fr
      "text text" 1fr / 1fr;
  }
  .main-container-image {
    width: 200px;
    min-width: 0px;
    height: 200px;
    min-height: 0px;
  }
  .main-container-buttons {
    min-width: 90%;
  }
  .main-container-buttons > button {
    min-width: 150px;
  }
  .main-container-stats {
    flex-direction: column;
  }
  .main-container-stats-unit {
    padding-bottom: 20px;
  }
  .main-container-stats-unit-title {
    padding-bottom: 10px;
    font-size: 1.2rem;
  }

  .main-container-stats-unit-value {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 390px) {
  .main-container-buttons {
    min-width: 90%;
  }
  .main-container-buttons > button {
    min-width: 35vw;
    height: 30px;
  }
}

@media (orientation: landscape) and (max-width: 915px) {
  .main-container {
    height: auto;
  }
  .main-container-image {
    min-width: 250px;
    min-height: 250px;
  }
}

.header-container {
  height: 88px;
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 25;
}

.header-container-logo {
  cursor: pointer;
  width: 150px;
  fill: white;
  transition: fill ease .4s;
  z-index: 15;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-container-logo svg {
  height: 40px;
}

.header-container-logo:hover {
  fill: #f2e370;
  transition: fill ease .4s;
}

.header-container-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

.header-container-content-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-container-content-socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 175.54px;
  left: 8px;
}

.header-container-content-socials-link {
  cursor: pointer;
  fill: white;
  transition: fill ease .4s;
  width: 23.5px;
  height: 23.5px;
}

.header-container-content-socials-link:hover {
  fill: #f2e370;
  transition: fill ease .4s;
}

.header-container-content-links-link {
  margin: 0 26px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 400;
  transition: color ease .4s;
}

.header-container-content-links-droplink {
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: 400;
  z-index: 6;
  letter-spacing: .6px;
  width: 153.49px;
}

.header-container-content-links-link:hover {
  color: #f2e370;
  transition: color ease .4s;
}

.header-container-content-links-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: absolute;
  top: 50px;
  height: 100px;
  z-index: 25;
  padding-top: 30px;
  width: 150.49px;
  padding-bottom: 20px;
  border-radius: 3px;
}

.header-container-content-links-dropdown-resources {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: absolute;
  margin-left: 155px;
  top: 50px;
  height: 100px;
  z-index: 25;
  padding-top: 30px;
  width: 150.49px;
  padding-bottom: 20px;
  border-radius: 3px;
}

.header-container-content-links-dropdown-resources {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: absolute;
  top: 50px;
  height: 100px;
  z-index: 25;
  padding-top: 30px;
  width: 150.49px;
  padding-bottom: 20px;
  border-radius: 3px;
}

.header-container-side-content {
  background: linear-gradient(90deg, rgba(20,50,82,1) 0%, rgba(47,88,133,1) 100%);
}

.header-container-burger,
.header-container-side-content,
.header-container-side-content-cross {
  display: none;
}

.header-container-side-content-links {
  padding-top: 88px;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  height: 45vh;
}

.header-container-side-content-links-link,
.header-container-side-content-links-droplink {
  cursor: pointer;
  color: white;
  font-size: 2.3vh;
  font-weight: 500;
  transition: color ease .4s;
}

.header-container-side-content-links-droplink {
  cursor: default;
  color: #f2e370;
}

.header-container-side-content-links-link:hover {
  color: #f2e370;
  transition: color ease .4s;
}

.header-container-side-content-links-dropdown,
.header-container-side-content-links-dropdown-resources {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 14vh;
}

.header-container-side-content-links-link,
.header-container-side-content-links-droplink {
  margin: 0;
}

@media screen and (max-width: 959px) {
  .header-container-content {
    display: none;
  }
  .header-container-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 26px;
    fill: white;
    cursor: pointer;
  }
  .header-container-side-content-cross {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 28px;
    width: 10vw;
    position: absolute;
    right: 0;
    top: 30px;
    cursor: pointer;
  }
  .header-container-side-content-cross:before,
  .header-container-side-content-cross:after {
    position: absolute;
    content: ' ';
    height: 28px;
    width: 2px;
    background-color: white;
    left: calc(50% - 20px);
  }
  .header-container-side-content-cross:before {
    transform: rotate(45deg);
  }
  .header-container-side-content-cross:after {
    transform: rotate(-45deg);
  }
  .header-container-side-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* min-height: 70vh; */
    background-color: #45688f;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: overlay;
    overflow-y: hidden;
    z-index: 5;
  }
  .header-container-content-socials {
    padding-left: 5%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    left: 0;
    width: 260px;
  }
  .header-container-content-socials svg {
    margin: 0;
    margin-right: 1.5em;
  }
}

@media screen and (max-width: 720px) {
  .header-container-logo {
    min-width: 120px;
  }
  .header-container-side-content-links-droplink {
    font-weight: normal !important;
  }
}

@media (orientation: landscape) and (max-width: 916px) {
  .header-container-logo svg {
    /* height: 10vh; */
  }
  .header-container-logo {
    min-width: 100px;
  }
  .header-container-burger div {
    width: 3vw;
    height: 1px;
  }
  .header-container-burger {
    left: 87vw;
  }
  .header-container-side-content-cross {
    height: 28px;
    right: 0;
  }
  .header-container-side-content-cross:after,
  .header-container-side-content-cross:before {
    height: 28px;
    width: 2px;
  }
  .header-container-side-content-links-link,
  .header-container-side-content-links-droplink {
    /* font-size: .9em; */
  }
  .header-container-side-content-links-dropdown {
  }
  .header-container-side-content-links {
    width: 260px;
  }
  .header-container-side-content {
    /* min-height: 80vh; */
  }
  .header-container-side-content-links-dropdown,
  .header-container-side-content-links-dropdown-resources {
    height: 11vh;
  }
}

@media (orientation: landscape) and (max-width: 654px) {
  .header-container-logo {
    min-width: 80px;
  }
  .header-container-side-content-links-dropdown {
    height: 25vh;
  }
  .header-container-side-content-links {
    height: 55vh;
  }
}

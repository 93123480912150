.App {
  text-align: center;
  background: linear-gradient(90deg, rgba(20,50,82,1) 0%, rgba(47,88,133,1) 100%);
  overflow-x: hidden;
}

pre {
  margin: 0;
}

.ghost-canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
  left: 0;
	opacity: 0.1;
}

.app-content {
	width: 100%;
	background: transparent;
  height: 100vh;
	font-family: "Ubuntu";
}

.modal-container {
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #183350;
  background-image: url("https://blog.ghostchain.io/wp-content/uploads/2022/10/GHST-Pattern.jpg");
  background-repeat: repeat;
  background-size: 4vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  color: white;
  cursor: default;

  animation: animatedBackground 30s linear infinite;
}
